import React from "react"
import { getRecentPages } from "../../services/account-services"
import { isLoggedIn } from "../../services/auth"

const RecentViews = () => {
    var recentPages = []
    recentPages = getRecentPages()
    return (
    <>
      {recentPages.length > 0 && (
        <div className="mt-30 mb-30 border-bottom-7px">
          <div className="row align-items-center">
            <div className="col-md">
              <h3 className="product-name-title d-flex align-items-center">
                Recently Viewed
              </h3>
            </div>
            {/* <div className="col-md-auto">
              <button type="button" className="button-link d-none d-lg-block">
                View All
              </button>
            </div> */}
          </div>
        </div>
        )}
        {recentPages.length > 0 && (
        <div className="row recent-view">
          {recentPages.map((page, idx) => {
            let imagePath = ''
            let pageUrl = ''
            if(page.className == 'HomeDesign' || page.className == 'Selection'){
                switch (page.className) {
                    case 'HomeDesign':
                      imagePath = page.imagePath
                      pageUrl = '/explore/home-designs/' + page.urlSegment
                      break
                    case 'Selection':
                      imagePath = page.imagePath
                      pageUrl = '/explore/style/' + page.urlSegment + '/'
                      break
                    default:
                      break
                  }
                  return <div key={`page-${idx}`} className="col-6 col-md-4 col-lg-3 col-xl-2">
                    <div className="grid-item">
                      <a href={pageUrl} className="grid-item-inner default-crop">
                        <img src={imagePath} />
                        <div className="grid-caption">
                          <h4>Demo title</h4>
                          <div className="grid-bottom">
                            <p className="variant-name">brass</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
            }else{
                if(typeof page.FeaturedVariation === 'undefined') return false
                switch (page.className) {
                    case 'Cabinetry':
                      imagePath = page.FeaturedVariation.UndigitalCabinetryVariation.featureImagePath
                      pageUrl = '/explore/cabinetry/' + page.urlSegment
                      break
                    case 'HomeDesign':
                      imagePath = page.imagePath
                      pageUrl = '/explore/home-designs/' + page.urlSegment
                      break
                    case 'Facade':
                      imagePath = page.FeaturedVariation.UndigitalFacadeVariation.featureImagePath
                      pageUrl = '/explore/facades/' + page.urlSegment + '/'
                      break
                    case 'FinishesFixtures':
                      imagePath = page.FeaturedVariation.UndigitalFinishesFixturesVariation.featureProductImagePath
                      pageUrl = '/explore/finishes-fixtures/' + page.urlSegment + '/'
                      break
                    case 'Furniture':
                      imagePath = page.FeaturedVariation.UndigitalFurnitureVariation.productImagePath
                      pageUrl = '/explore/furniture/' + page.urlSegment + '/'
                      break
                    case 'Selection':
                      imagePath = page.imagePath
                      pageUrl = '/explore/style/' + page.urlSegment + '/'
                      break
                    default:
                      break
                }
                return <div key={`page-${idx}`} className="col-6 col-md-4 col-lg-3 col-xl-2">
                  <div className="grid-item">
                    <a href={pageUrl} className="grid-item-inner default-crop">
                      <img src={imagePath} />
                      <div className="grid-caption">
                        <h4>Demo title</h4>
                        <div className="grid-bottom">
                          <p className="variant-name">brass</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
            }
          })}
        </div>
        )}
    </>
    )
}

export default RecentViews

import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import "react-input-range/lib/css/index.css"
import useOutsideClick from "../../../hooks/useOutsideClick"
import { handleLogin, isLoggedIn, getUser } from "../../../services/auth"

import Layout from "../../../templates/Page"
import FilterResult from "../../ContentBuilder/FilterResult"
import MainDesignFilter from "../../../templates/Includes/MainDesignFilter"
import StayInTouch from "../../../templates/Includes/StayInTouch"
import SidebarFilter from "../../../templates/Includes/SidebarFilter"

import SearchBar from "../../SearchBar"
import ContactUs from "../../ContactUs"
import Elements from "../../../templates/Elements/Elements"
import CreateBoardModal from "../../ContentBuilder/Modal/CreateBoardModal"
import { connect } from "react-redux"
import { doFilter } from "../../../services/filter"
import QuickViewModalData from "../../ContentBuilder/Modal/QuickViewModalData"
import { setCurrent } from "../../../state/nav"
import config from "../../../config/config"
import { getBoardFromLocalStorage } from "../../../state/board"
import RecentViews from "../../RecentViews"
import { resetFilter } from "../../../state/filter"

const StyleList = ({ activeFilters, currentBoards, sort, dispatch }) => {
  useEffect(() => {
    dispatch(setCurrent('style'))
    dispatch(getBoardFromLocalStorage())
  }, [])
  const [show, setShow] = useState(false)
  const [items, setItems] = useState([])
  const ref = useRef()
  useOutsideClick(ref, () => {
    setShow(false)
  })

  const resetFilterHandler = useCallback(() => dispatch(resetFilter()), [])

  const filterEl = useMemo(() => <AllStyles activeFilters={activeFilters} currentBoards={currentBoards} sort={sort} setItems={data => setItems(data)} resetFilter={resetFilterHandler} />, [activeFilters, currentBoards, sort])

  return (
    <Layout>
      <div className="container">
        <MainDesignFilter />
        <div className="wrap-section no-margin">
          <div className="explore-function-content">
            <FilterResult />
          </div>
        </div>

        <div className="wrap-section explore-wrap row">
          <div className="col-lg-3">
            <SidebarFilter items={items} />
          </div>
          {filterEl}
        </div>
        <RecentViews />
      </div>
      <StayInTouch />
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  activeFilters: state.filter.active,
  currentBoards: state.board.all,
  sort: state.sort.current
})

export default connect(mapStateToProps, null)(StyleList)

function AllStyles({ activeFilters, currentBoards, sort, setItems, resetFilter }) {
  const loadPage = () => {
    pageLimit = pageLimit + 12
    setPageLimit(pageLimit)
    if (pageLimit >= _total_items) {
      setHasMore(false)
    }
  }
  var [pageLimit, setPageLimit] = useState(12)
  var [hasMore, setHasMore] = useState(true)

  const allItems = useStaticQuery(graphql`
    {
        silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
        UndigitalGlobalConfig {
          hidePricingGlobally
        }
      }
      allSilverStripeDataObject(filter: {className: {eq: "Undigital__Objects__Selection"}, UndigitalSelection: {status: {eq: "Active"}}}, sort: {fields: UndigitalSelection___objectID, order: DESC}) {
        nodes {
          UndigitalSelection {
            name
            showTitleOnTile
            tileBlackTitle
            urlSegment
            objectID
            about
            imagePath
            imagePosition
            styleID
            Items {
              UndigitalSelectionItem {
                name
                status
                productImagePath
              }
            }
          }
        }
      }
    }
  `)
  const host = config.host
  const globalConfig = allItems.silverStripeDataObject.UndigitalGlobalConfig
  const [showAddBoard, setShowAddBoard] = useState(false)
  const url = typeof window !== "undefined" ? window.location.pathname : ""
  var checkURL = url.substring(0, url.length - 1)
  const segment = checkURL.substring(checkURL.lastIndexOf("/") + 1)
  const search = typeof window !== "undefined" ? window.location.search : "/"
  const params = new URLSearchParams(search)
  // var sortBy = params.get("sort")
  var keyword = params.get("keyword")
  const unfiltered_items = allItems.allSilverStripeDataObject.nodes
  let _allData = []
  var i = 0
  for (; unfiltered_items[i];) {
    var _item = unfiltered_items[i].UndigitalSelection
    _item.link = '/explore/style/' + _item.urlSegment;
    _item.className = 'Selection';
    _item.FeaturedImage = _item.imagePath
    var name = _item.name
    var match = true
    if (keyword) {
      keyword = keyword.toLowerCase()
      let _name = name.toLowerCase()

      let matched_name = _name.search(keyword)

      if (matched_name === -1) {
        match = false
      }
    }

    if (_item.styleID) _item.styleArray = [_item.styleID]

    if (match) {
      _allData.push(_item)
    }
    i++
  }

  // deep copy the original tiles
  const _allTiles = JSON.parse(JSON.stringify(_allData))
  useEffect(() => {
    setItems(_allTiles)
  }, [])
  // do filter
  if (activeFilters.length) _allData = doFilter(activeFilters, _allData)

  _allData.sort((a, b) => {
    if (sort === "az") {
      return a.name > b.name ? 1 : -1
    } else if (sort === "latest") {
      return Number(a.objectID) < Number(b.objectID) ? 1 : -1
    } else {
      return Number(a.objectID) < Number(b.objectID) ? 1 : -1
    }
  })

  const _total_items = _allData.length
  const currentData = _allData.slice(0, pageLimit)
  if (_total_items == currentData.length) {
    hasMore = false
  }

  const [showQuickView, setShowQuickView] = useState(false);
  const [quickViewData, setQuickViewData] = useState(null);
  const setUpQuickViewModal = useCallback((e, item) => {
    e.preventDefault();
    setQuickViewData(item);
    setShowQuickView(true);
  }, [])

  const [objectClass, setObjectClass] = useState();
  const [objectID, setObjectID] = useState();
  const [variantID, setVariantID] = useState(null);
  const setShowBoard = (className, objectID) => {
    if (isLoggedIn()) {
      setObjectClass(className);
      setObjectID(objectID);
      setShowAddBoard(true);
    } else {
      if (typeof window !== 'undefined') {
        window.location.href = "/login/";
      }
    }
  }

  return (
    <>
      <div className="col-lg-9">
        {currentData.length > 0 ? (
          <div className="grid">
            {currentData.map(item => {
              let isSaved = false
              for (const board of currentBoards) {
                const items = JSON.parse(board.Items)
                for (const key in items) {
                  if (key.includes(item.className) && items[key].ID == item.objectID) {
                    isSaved = true
                  }
                }
              }
              return (
                <div key={item.urlSegment} className={`grid-item ${isSaved ? 'liked' : ''} ${item.showTitleOnTile == 1 ? ' showtitle' : ''} ${item.tileBlackTitle == 1 ? 'text-black' : ''} ${item.Image ? '' : ' no-image'} ${item.imagePosition}`}>
                  <button
                    type="button"
                    className="btn-like"
                    onClick={() => setShowBoard(item.className, item.objectID)}
                  >
                    {isSaved ? (
                      <>
                        <svg className="clear-heart" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                          <path id="Path_878" data-name="Path 878" d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z" transform="translate(21.982 6.975)" fill="#fff" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
                        </svg>
                        <div className="clear-item"></div>
                      </>
                    ) : <svg id="icon-heart" width="35" height="35" viewBox="0 0 35 35"><path d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z" transform="translate(21.982 6.975)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"></path></svg>}
                  </button>
                  <Link onClick={e => setUpQuickViewModal(e, item)} className={`grid-item-inner ${item.imagePosition}`} key={item.urlSegment} to={`/explore/style/${item.urlSegment}/`} >
                    {item.productImagePath && (
                      <img src={item.productImagePath} alt={item.name} />
                    )}
                    <div className="grid-caption">
                      <h4>{item.name}</h4>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        ) : (
          <p>Awh dam! Sorry, we can't find what you're looking for. Try a different search or <a href="#" onClick={() => resetFilter()}>reset</a> your filters to continue exploring Birdy.</p>
        )}
        {currentData.length > 0 && hasMore && (
          <div className="text-center">
            <button
              onClick={loadPage}
              className="btn btn-black btn-lg mt-5"
            >
              LOAD MORE
            </button>
          </div>
        )}
      </div>
      <CreateBoardModal
        show={showAddBoard}
        handleClose={() => setShowAddBoard(false)}
        objectClass={objectClass}
        variantID={variantID}
        objectID={objectID}
      />
      {quickViewData !== null && (
        <QuickViewModalData
          show={showQuickView}
          handleClose={() => setShowQuickView(false)}
          item={quickViewData}
          hidePricingGlobally={globalConfig.hidePricingGlobally}
        />
      )}
    </>
  )
}
